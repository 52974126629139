












































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'Question1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: [],
        input4: [],
        input5: [],
        input6: [],
        input7: [],
        input8: [],
        input9: [],
      },
    };
  },
  computed: {
    statements(): string[] {
      return [
        'a) Possesses a negative charge',
        'b) Has no charge',
        'c) Has a mass less than that of a neutron',
        'd) Has a charge equal but opposite that of a proton',
        'e) Is not found in the nucleus',
        'f) Can be called a nucleon',
        'g) Is the heaviest of the three subatomic particles',
        'h) Has a relative mass of 1836 if the mass of an electron is 1',
        'i) Has a relative mass of 1839 if the mass of an electron is 1',
      ];
    },
  },
});
